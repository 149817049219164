<template>
<div class="container">
    <div class="userAvatar">
      <el-upload
        action="/api/upload"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
        <el-avatar :src="circleUrl"></el-avatar>        
      </el-upload>
      <el-button type="primary" size="mini" @click="openCropPage" class="button">头像裁剪</el-button>
    </div>
  <el-dialog title="头像裁剪"
             :visible.sync="showCropPage"
             width="30%">
    <div class="test">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="true"
          :full="option.full"
          :canMove="option.canMove"
          :canScale="option.canScale"
          :canMoveBox="option.canMoveBox"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :fixedBox="option.fixedBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :centerBox="option.centerBox"
          :high="option.high"
          :infoTrue="option.infoTrue"
          :enlarge="option.enlarge"
        ></vueCropper>
    </div>
    <el-button type="primary" class="updateButton" @click="uploadImg('blob')">确认裁剪</el-button>
  </el-dialog>
</div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AvatarChoice',
  data(){
    return{
        imageUrl: '',
        showCropPage:false,
        circleUrl: '/api/public/imgs/avatar.png',
        option: {
                  img:'', // 裁剪图片的地址  空 url 地址 || base64 || blob
                  outputSize: 1, // 裁剪生成图片的质量 1 0.1 - 1
                  outputType: "jepg", //  裁剪生成图片的格式 jpg (jpg 需要传入jpeg)  jpeg || png || webp
                  info: false, //  裁剪框的大小信息  true  true || false
                  canScale: true, // 图片是否允许滚轮缩放  true  true || false
                  autoCrop: true, //是否默认生成截图框 false true || false
                  autoCropWidth: 200, //默认生成截图框宽度 容器的80%  0~max
                  autoCropHeight: 160, //默认生成截图框高度  容器的80%  0~max
                  fixed: false, //是否开启截图框宽高固定比例  true  true | false
                  fixedNumber: [1, 1], //截图框的宽高比例 [1, 1]  [宽度, 高度]
                  full: false, //是否输出原图比例的截图  false true | false
                  fixedBox: false, //固定截图框大小 不允许改变  false true | false
                  canMove: false, //上传图片是否可以移动  true  true | false
                  canMoveBox: true, //截图框能否拖动 true  true | false
                  original: false, //上传图片按照原始比例渲染 false true | false
                  centerBox: true, //截图框是否被限制在图片里面  false true | false
                  high: true, //是否按照设备的dpr 输出等比例图片  true  true | false
                  infoTrue: true, //true 为展示真实输出图片宽高 false 展示看到的截图框宽高 false true | false
                  maxImgSize: 2000, //限制图片最大宽度和高度 2000  0-max
                  enlarge: 1, //图片根据截图框输出比例倍数 1 0-max(建议不要太大不然会卡死的呢)
                  mode: "contain" //图片默认渲染方式  contain contain , cover, 100px, 100% auto
              },
    }
  },
  methods:{
    uploadImg (type) {
      if (type === 'blob') {
        //获取截图的blob数据
        this.$refs.cropper.getCropBlob(async (data) => {
          let formData = new FormData();
          formData.append('file',data,"avatar.png")
          //调用axios上传
          axios.post('/api/upload', formData).then( res => {
            console.log(res)
            if(res.status === 200){
               this.$confirm('裁剪成功！', '提示', {
                  confirmButtonText: '确定',
                  showCancelButton:false,
                  type: 'success'}).then( ()=>{
                     location.reload()
                  })
            } else{
                this.$message.error('出错了')
            }   
          })
        })
      }
    },
    openCropPage(){
      this.showCropPage = true,
      this.option.img = '/api/public/imgs/avatar.png'
    },
    handleAvatarSuccess(res, file) {
      console.log(file)
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.imageUrl)
      location.reload()
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },

  },
  created(){
    this.imageUrl = '/api/public/imgs/avatar.png'
  },
  
}
</script>

<style scoped>
.test{
  width: 300px;
  height: 300px;
  margin: 0 auto;
}
.updateButton{
  margin: 30px 0 20px 150px;
}
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-avatar {
    width: 100px;
    height: 100px;
  }
  .userAvatar{
    margin-left: 50px;
    margin-top: 30px;
  }
  .button{
    margin-left: 10px;
    margin-top: 20px;
  }
</style>
