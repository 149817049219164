<template>
  <div class="home">
    <div class="topbanner">
      <div class="content">
        <div class="avatar">
          <el-avatar :size="50" :src="circleUrl"></el-avatar>
        </div>
        <div class="username">可莉</div>
      </div>
    </div>
    <avatar-choice></avatar-choice>
  </div>
</template>

<script>
import AvatarChoice from '../components/AvatarChoice.vue'
export default {
  name: 'Home',
  components: {
    AvatarChoice
  },
  data(){
    return{
        circleUrl: '/api/public/imgs/avatar.png',
    }
  }
}
</script>

<style scoped>
.topbanner{
  height: 64px;
  background-color:		#FFE4E1;
}
.content{
  display: flex;
  justify-content: flex-end;
}
.avatar{
  margin-top: 7px;
}
.username{
  margin: 20px 30px 20px 10px;
}
</style>
